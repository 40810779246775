const axios = require('axios');

/**
 * The purpose of next two lines is to
 * Call apiController with request->wantsJson set to false
 *
 * This is becasue we do not consume apis within the same application, we call apiControllers from webControllers instead
 */
axios.defaults.headers.get['Accept'] = '*/*';
axios.defaults.headers.post['Accept'] = '*/*';

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// Function to update CSRF token in Axios headers
const updateCsrfToken = token => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = token;
};

axios.interceptors.response.use(
	(response) => {
		const csrfToken = response.headers['x-csrf-token'];
        if (csrfToken) {
            updateCsrfToken(csrfToken);
        }
        return response;
	},
	function (error) {
	    let errorResponses = {
	        401 : 'Unauthenticated.',
	        419 : 'CSRF token mismatch.'
	    };

	    if(error.response) {
	        if(error.response.status in errorResponses) {
	            let errorObj = {
                    clientError : true,
                    message : errorResponses[error.response.status]
	            }

                error.response.data = {...error.response.data, ...errorObj};
	            
	            return Promise.reject(error);
	        }

	        if(error.response.status === 422) {
	            let errors = {};
	            for(let err in error.response.data.errors) {
	                errors[err] = {
	                    message: error.response.data.errors[err][0],
	                    show: true
	                }
	            }
	            error.response.data.validationErrors = errors;
	        }

	        return Promise.reject(error);
	    }
	    else {
			console.log(error);
	        if(!('response' in error)){
	            let errorObj = {
                    clientError : true,
                    message : 'Bad request on client side, if the issue persist then please contact us on support@dnaforinsta.com for further assistance'
	            }
                error = {...error, 'response': {data: {...errorObj}}};
	        }
	        return Promise.reject(error);
	    }
	}
);

window.axios = axios;
