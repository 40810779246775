/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require('popper.js').default;

    window.$ = window.jQuery = require('jquery');
    require('jquery-validation');
    require('daterangepicker');
    require('bootstrap');
} catch (e) { }

let mmt = require('moment');
window.moment = (date = null, format = null) => {
    return date && format
        ? mmt.utc(date, format)
        : date
            ? mmt.utc(date)
            : mmt.utc();
};
